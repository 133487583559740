/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import headerBG from 'assets/img/video.mp4';

const liveChatScript =
  "'use strict';!function() {var t = window.driftt = window.drift = window.driftt || [];if (!t.init) { if (t.invoked) return void (window.console && console.error && console.error('Drift snippet included twice.'));t.invoked = !0, t.methods = [ 'identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on' ], t.factory = function(e) {return function() {var n = Array.prototype.slice.call(arguments);return n.unshift(e), t.push(n), t;};}, t.methods.forEach(function(e) {t[e] = t.factory(e);}), t.load = function(t) {var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement('script');o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js';var i = document.getElementsByTagName('script')[0];i.parentNode.insertBefore(o, i);};}}(); drift.SNIPPET_VERSION = '0.3.1'; drift.load('uecxeyeykmcf');";

const googleScript =
  "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-22235291-51');";

const googleTag =
  "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-626926406');";

const pixelScript =
  "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '589455738449945'); fbq('track', 'PageView');";


function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>

    <script src="https://smtpjs.com/v3/smtp.js"></script>

          <script
              type="text/javascript"
              data-cfasync="false"
              dangerouslySetInnerHTML={{ __html: liveChatScript }}
          />  

          <script
            type="text/javascript"
            data-cfasync="false"
            dangerouslySetInnerHTML={{ __html: googleScript }}
          />
          <script
            type="text/javascript"
            data-cfasync="false"
            dangerouslySetInnerHTML={{ __html: pixelScript }}
          />
          <script
            type="text/javascript"
            data-cfasync="false"
            dangerouslySetInnerHTML={{ __html: googleTag }}
          />
          

      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/banner-bg.webp") + ")",
          }}
          ref={pageHeader}
        >
          <video className='videoTag' autoPlay loop muted>
              <source src={headerBG} type='video/mp4' />
          </video>
        </div>
        <Container>
          <div className="content-center brand">
            <img
              alt="Cybersecurity Experts | Nexone Technologies"
              className="header-logo"
              src={require("assets/img/nexone-logo.png")}
            ></img>
            <br /><br /><br />
            <h1 className="h1-seo">Managed IT Services</h1>
            <h3>Solving Business-Critical Challenges
With the Latest Technologies & Processes</h3>

          </div>
          
        </Container>
      </div>

        <div className="nexone-angled-top">
        <Row>
        <Col className="ml-auto mr-auto" md="6">
          <div class="angled-divider angled-divider-white">
            <div class="gradient-accent gradient-accent-to-right gradient-accent-orange">
              <h3>PROTECTING YOUR BUSINESS</h3>
              <h2>Enterprise CyberSecurity</h2>
            </div>
          </div>
        </Col>
        <Col className="ml-auto mr-auto" md="6">
          <div className="nexone-section-top">
            <div className="border-bottom-right"></div>
            <p>Cybersecurity isn’t reserved for major corporations that collect huge sets of credit data and personal information.  Every company that relies on the internet must be aware of today’s cybersecurity risks and take steps to close vulnerabilities.</p>
            <p>Beyond developing plans for our clients, implementing the strategies and proactively monitoring their digital security, it’s our role as a managed services provider to educate technology-reliant businesses on cybersecurity best practices.</p>
            <a href="/cybersecurity" class="btn btn-link btn-lg nexone-link">Cybersecurity Solutions <i class="now-ui-icons nexone-icon arrows-1_minimal-right"></i></a>
            <a href="/consultation" class="btn btn-link btn-lg nexone-link">Is Your Network Secure? <i class="now-ui-icons nexone-icon arrows-1_minimal-right"></i></a>
          </div>
        </Col>
        </Row>
        </div>
      



      <div className="nexone-angled-background angled-background-down">

        <div class="angled-background-accent angled-background-accent-top-right">
        <div class="gradient-accent gradient-accent-to-left gradient-accent-gold"></div></div>
        <div class="angled-background no-overflow">
          <div className="nexone-angled-background-image">
            <img className="nexone-background-angled" src={require("assets/img/nexone-background-2.webp")}>
            </img>
          </div>
        </div>
        <div class="angled-background-content entry-content-dark">
      
          <div class="text-header">
            <h4 class="has-text-align-center"><strong>NEXONE TECHNOLOGIES SERVICES</strong></h4>
            <h1 class="has-text-align-center">Services For Your Business</h1>
            <p class="has-text-align-center">Recognized Leader & trusted IT Managed Service Provider (MSP). NexOne’s highly experienced and certified team works along-side our partners accross the nation to find and deliver solutions that reduce business costs and complexity, minimizing risks and downtime.</p>
            <a href="/solutions" class="btn btn-link btn-lg nexone-link-white">LEARN MORE ABOUT OUR IT SOLUTIONS <i class="now-ui-icons nexone-icon-lighter arrows-1_minimal-right"></i></a>
          </div>

        </div>

      </div>

      <div className="nexone-partners">
        <div class="row">
          <div class="col-md-6">
            <img
              alt="Partnered with Nexone Technologies"
              src={require("assets/img/nexone-partners.jpg")}
            ></img>
          </div>
          <div class="col-md-6">
            <div className="border-bottom-right"></div>
            <h4 class="has-text-align-center"><strong>TECHNOLOGY PARTNERS</strong></h4>
            <h1 class="has-text-align-center">Partnered with Top Vendors</h1>
            <p class="has-text-align-center">We Can't do it alone. Our partners support us and allow us to provide top-tier service to our clients.</p>
          </div>
        </div>
      </div>

      <div className="nexone-awards">
        <div class="row">
          <div class="col-md-6">
            <h4 class="has-text-align-center"><strong>NexOne Technologies</strong></h4>
            <h1 class="has-text-align-center">Recognized Leader in IT Services</h1>
          </div>
          <div class="col-md-6">
            <img
              alt="Partnered with Nexone Technologies"
              src={require("assets/img/nexone-clutch-award.png")}
            ></img>
            <img
              alt="Partnered with Nexone Technologies"
              src={require("assets/img/nexone-award.png")}
            ></img>

          </div>
        </div>
      </div>

      <div className="nexone-consultation-footer">
        <div class="row">
          <div class="col-md-6">
            <h3 class="has-text-align-center">Get a Free Consultation</h3>
          </div>
          <div class="col-md-6">
            <a href="/consultation" type="button" class="btn-round btn btn-outline-info">Get Started</a>
          </div>
        </div>
      </div>

    </>
  );
}

export default IndexHeader;
