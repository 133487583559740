import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import PagesNavbar from "components/Navbars/PagesNavbar.js";
import CybersecurityHeader from "components/Headers/CybersecurityHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PagesNavbar />
      <div className="wrapper">
        <CybersecurityHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto cyber-1" md="6">
              <h5 className="upper-title">NEXONE CYBERSECURITY SOLUTIONS</h5>

                <h2 className="title">Cybersecurity Solutions</h2>

                <h5 className="description">
                  Leading Enterprise Protection for your Business.
                </h5>

              </Col>
              <Col className="ml-auto mr-auto cyber-2" md="6">
                <div className="border-top-right"></div>
                
                <img alt="Cybersecurity Experts, NexOne Technologies" className="about-left" src={require("assets/img/cybersecurity-complete.svg")}></img>  
              
              </Col>

            </Row>
          </Container>

            <div className="separator separator-primary"></div>
            <div className="section-cybersecurity-approach">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Planning, NexOne Technologies" className="cyber-approach-left" src={require("assets/img/cybersecurity-approach.svg")}></img>  
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">

                    <h2 className="title">Our Approach</h2>
                    <h5 className="description"> 
                    <ul>
                      <li>Review existing security policies and implementations.</li>
                      <li>Develop a cybersecurity plan specific to your business.</li>
                      <li>Develop and implement new network policies and security.</li>
                      <li>Continuous Vulnerability Scanning, Intrusion Detection & Prevention.</li> 
                    </ul>
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link">
                      <a href="https://app.purechat.com/w/nexone" target="_blank">CHAT WITH US NOW</a>
                    </div>
                    <div className="cyber-border-right-bottom"></div>

                  </Col>
                </Row>
              </Container>
            </div>

            <div className="separator separator-primary"></div>

            <div className="section-cyber-identity">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">Access & Identity Management</h2>
                    <h5 className="description"> 
                      Directory Services: Directory Services such as Microsoft Active Directory allows a business to centrally manage networks, computers and all users.
                    </h5>

                    <h2 className="title">Single Sign-On (SSO)</h2>
                    <h5 className="description"> 
                      Directory Services: Directory Services such as Microsoft Active Directory allows a business to centrally manage networks, computers and all users.
                    </h5>
                    
                    <h2 className="title">MFA (Two-Factor Authentication)</h2>
                    <h5 className="description"> 
                      Two-Factor Authentication: Sometimes referred to as two-step verification, multi-factor authentication or dual-factor authentication, is a security process in which users provide two different authentication factors to verify themselves. This process is done to better protect both the user's credentials and the resources the user can access.
                    </h5>
                  </Col>

                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Experts, NexOne Technologies" className="cyber-right" src={require("assets/img/cyber-identity.svg")}></img>  
                  </Col>
                </Row>
              </Container>
            </div>


            <div className="section-cyber-antivirus">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto text-center" md="6">
                   
                    <img alt="Cybersecurity Experts, NexOne Technologies" src={require("assets/img/cyber-antivirus.svg")}></img>  
                 
                  </Col>

                  <Col className="ml-auto mr-auto" md="6">

                   <h2 className="title">Antivirus Protection (Next-Gen)</h2>
                    <h5 className="description"> 
                      Enterprise level, next generation endpoint protection platform. While active, the agent will protect the endpoint by preventing attacks from known threats, detecting and stopping undesired behavior (such as Ransomware) during an attack, and by mitigating and remediating the endpoint after an attack has been stopped.
                    </h5>

                   </Col>
                </Row>
              </Container>
            </div>

            <div className="section-cyber-identity">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">Dark Web Monitoring</h2>
                    <h5 className="description"> 
                      Monitoring the Dark Web for stolen credentials is critical if you want to provide comprehensive security to your business. Complementing dark web monitoring with Simulated Phishing Attacks and security awareness training campaigns to educate employees, making them the best defense against cybercrime.
                    </h5>
                  </Col>

                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Experts, NexOne Technologies" className="cyber-right" src={require("assets/img/cyber-darkweb.svg")}></img>  
                  </Col>
                </Row>
              </Container>
            </div>

        </div>

          <div className="above-footer-consult">
            <Container>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <span>Get a Free Consultation</span>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <a href="/consultation" target="_blank" className="consult-button">Get Started</a>
                      </Col>
                    </Row>
            </Container>
          </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
