import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import PagesNavbar from "components/Navbars/PagesNavbar.js";
//import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [secondFocus, setSecondFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PagesNavbar />
      <div className="wrapper support-page">
        
        <div className="section section-support">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">NexOne Support</h2>
                <div className="has-decoration"></div>
                <h5 className="description">
                  Methods of Contact for Support
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="support-options">
              <Row>
                <Col md="3">
                  <div className="bg-white support-item"> 
                    <a href="http://nexone.portal.mspmanager.com/" target="_blank"> 
                      <img alt="Cybersecurity Experts | Nexone Technologies" className="header-logo" src={require("assets/img/client-portal.png")}></img>
                      <div className="s_title bold">Client Portal</div>
                      <p>If you are a client & do not have access, contact your NexOne Representative.</p>
                    </a>
                  </div>
                </Col>
                <Col md="3">
                  <div className="bg-white support-item"> 
                    <a href="mailto:support@nexoneit.com" target="_blank"> 
                      <img alt="Cybersecurity Experts | Nexone Technologies" className="header-logo" src={require("assets/img/email-icon.png")}></img>
                      <div className="s_title bold">Email Us</div>
                      <p>Send us an email to<br />support@nexoneit.com</p>
                    </a>
                  </div>
                </Col>
                <Col md="3">
                  <div className="bg-white support-item"> 
                    <a href="sms://+14074340378"> 
                      <img alt="Cybersecurity Experts | Nexone Technologies" className="header-logo" src={require("assets/img/text-icon.png")}></img>
                      <div className="s_title bold">TEXT US</div>
                      <p>Send a text<br />to (407) 434-0378</p>
                    </a>
                  </div>
                </Col>
                <Col md="3">
                <div className="bg-white support-item"> 
                    <a href="tel://+18558656351"> 
                      <img alt="Cybersecurity Experts | Nexone Technologies" className="header-logo" src={require("assets/img/call-icon.png")}></img>
                      <div className="s_title bold">Call Now</div>
                      <p>9am-5pm (EST)<br />Phone: (855) 865-6351</p>
                    </a>
                  </div>
                </Col>
              </Row>
              <div className="support-link"><a href="#">CHAT NOW</a>
              <div className="has-decoration"></div>
              <span>Click to open live chat with a support specialist</span></div>
            </div>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Want us to reach out to you instead?</h2>
            <p className="description">Your requests are very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">

                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Full Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>


                <InputGroup
                  className={
                    "input-lg" + (secondFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setSecondFocus(true)}
                    onBlur={() => setSecondFocus(false)}
                  ></Input>
                </InputGroup>


                <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons tech_mobile"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Phone Number..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                </Col>
                <Col className="ml-auto mr-auto text-center" md="4">

                    <i className="now-ui-icons ui-2_time-alarm"></i> 
                    <select id="cars" name="cars" id="navbarDropdown" data-toggle="dropdown" className="button-dropdown">
                      <option>Select Best Time</option>
                      <option value="9am-10am">9:00am-10:00am</option>
                      <option value="10am-11am">10:00am-11:00am</option>
                      <option value="11am-12pm">11:00am-12:00pm</option>
                      <option value="12pm-1pm">12:00pm-1:00pm</option>
                      <option value="1pm-2pm">1:00pm-2:00pm</option>
                      <option value="2pm-3pm">2:00pm-3:00pm</option>
                      <option value="3pm-4pm">3:00pm-4:00pm</option>
                      <option value="4pm-5pm">4:00pm-5:00pm</option>
                    </select>

                </Col>
                </Row>


                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
