import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import PagesNavbar from "components/Navbars/PagesNavbar.js";
import SolutionsHeader from "components/Headers/SolutionsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PagesNavbar />
      <div className="wrapper">
        <SolutionsHeader />
        <div className="section section-about-us section-solutions-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto it-solutions" md="6">
              <h5 className="upper-title">NEXONE TECHNOLOGIES</h5>

                <h2 className="title">IT Solutions</h2>

                <h5 className="description">
                  NexOne Technologies serves as the primary IT provider for our clients. We proactively manage every aspect of your infrastructure & cybersecurity while remotely monitoring every device and application on your network.
                  <br /><br />
                  We design, install, manage, and support industry leading IT solutions to secure your business’ data, enhance productivity, and support day-to-day IT operations.
                </h5>
                <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/consultation">Learn More</a>
                </div>
              </Col>
              <Col className="ml-auto mr-auto it-solutions-2" md="6">
                <div className="border-top-right"></div>
                
                <img alt="Cybersecurity Experts, NexOne Technologies" className="about-left" src={require("assets/img/it-solutions-top-right.svg")}></img>  
              
              </Col>

            </Row>
          </Container>
            <div id="msp"></div>
            <div className="separator separator-primary"></div>
            <div className="section-managed-it">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="IT Managed Service Provider, NexOne Technologies" className="cyber-approach-left" src={require("assets/img/nexone-msp-services.svg")}></img>  
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">

                    <h2 className="title">Managed IT Services</h2>
                    <h5 className="description"> 
                    Whether you have an existing IT team looking for help, or plan to outsource IT completely, we can help you remove the burden of managing technology and gain the freedom to focus on what matters most – growing your business.
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/consultation">Learn More</a>
                    </div>
                    <div className="cyber-border-right-bottom"></div>

                  </Col>
                </Row>
              </Container>
            </div>

            <div className="separator separator-primary"></div>

            <div className="section-cyber-identity">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">Cybersecurity</h2>
                    
                    <h5 className="description"> 
                      All managed services include basic cybersecurity to keep you secure, but additional layers of security can enhance these measures and make sure you’re one step ahead of hackers.
                      <br /><br />
                      Guarding your cyber security system and keeping it active and updated is a full time job. This is a responsibility many business owners simply do not have time for, and creating a dedicated department within the company overextends available resources. NexOne solves this problem with custom Cybersecurity consulting services tailored to suit your business.
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/cybersecurity">Learn More</a>
                    </div>
                  </Col>

                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Experts, NexOne Technologies" className="cyber-right" src={require("assets/img/cybersecurity.svg")}></img>  
                  </Col>
                </Row>
              </Container>
            </div>

            <div id="backups"></div>
            <div className="section-cyber-antivirus">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto text-center" md="6">
                   
                    <img alt="Cybersecurity Experts, NexOne Technologies" src={require("assets/img/backup-services.svg")}></img>  
                 
                  </Col>

                  <Col className="ml-auto mr-auto" md="6">

                   <h2 className="title">Backup & Disaster Recovery</h2>
                    <h5 className="description">
                    Modern business requires a modern data backup solution.
                    Even with a strong network security strategy in place, sound data backups are critical. You can greatly reduce your chances of a data breach by implementing a layered security strategy, but there is no foolproof method of prevention. 
                    <br /><br />
                    Therefore, data backups represent your only real guarantee against data loss or having to pay a ransom in the event of a ransomware attack.
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/consultation">Learn More</a>
                    </div>
                   </Col>
                </Row>
              </Container>
            </div>
            <div id="voip"></div>
            <div className="section-cyber-identity" >
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">VOIP Services</h2>
                    <h5 className="description"> 
                      The days of needing a landline to stay connected with your customers have come to an end, resulting in greater choice and flexibility for businesses of all sizes. With VoIP, you can use the Internet to make phone calls rather than relying on the phone company. 
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/consultation">Learn More</a>
                    </div>
                  </Col>

                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Experts, NexOne Technologies" className="cyber-right" src={require("assets/img/voip-services.svg")}></img>  
                  </Col>
                </Row>
              </Container>
            </div>

        </div>

        <div className="section-cloud-services" id="cloud">
              <Container>
                <Row>

                  <Col className="ml-auto mr-auto text-center" md="6">
                   
                    <img alt="Cloud Services and Hosting, NexOne Technologies" src={require("assets/img/cloud-services.svg")}></img>  
                 
                  </Col>

                  <Col className="ml-auto mr-auto" md="6">

                   <h2 className="title">Cloud Services</h2>
                    <h5 className="description">
                    In simple terms, Cloud solutions are service options that allow us to utilize off-premise computing power in much the same way we make use of utilities. 
                    <br /><br />
                    We don’t have to pay for installing, hosting and supporting these business functions, but we instead pay only for the server space or the portion of the application that we are using as part of a Cloud solution. 
                    </h5>
                    <div class="has-decoration"></div>
                    <div class="cyber-link-dark">
                      <a href="/consultation">Learn More</a>
                    </div>
                   </Col>
                </Row>
              </Container>
            </div>

          <div className="above-footer-consult">
            <Container>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <span>Get a Free Consultation</span>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <a href="/consultation" target="_blank" className="consult-button">Get Started</a>
                      </Col>
                    </Row>
            </Container>
          </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
