import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import PagesNavbar from "components/Navbars/PagesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PagesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img alt="Cybersecurity Experts, NexOne Technologies" className="about-left" src={require("assets/img/about-left.png")}></img>  
              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <div className="border-top-right"></div>
                <h5 className="upper-title">WHO WE ARE</h5>
                <h2 className="title">NexOne Technologies</h2>
                <h5 className="description">
                  NexOne is a technology solutions provider committed to help our clients develop and implement innovative technical solutions to solve their business needs. NexOne’s highly experienced and certified teams work along-side our partners across the U.S to find and deliver solutions that reduce business costs and complexity, minimize risks and downtime. We partner with our clients to deliver innovative IT Strategies that turn ideas into realities, helping them transform and grow their businesses.
                  <br /><br />
                  At NexOne, we truly care about the impact we make to our clients’ businesses and organizations. We want to inspire trust by not only having an ethical culture, but by taking effective actions that allow our clients’ to thrive. Our core values shape and define the culture of our company, they serve as our foundation and guide our decisions.            
                </h5>
              </Col>

            </Row>
          </Container>

            <div className="separator separator-primary"></div>
            <div className="section-mission-vision">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <h2 className="title">Mission</h2>
                    <h5 className="description">
                    To help clients develop and implement innovative solutions to solve their business needs. 
                    </h5>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <h2 className="title">Vision</h2>
                    <h5 className="description"> 
                    To be a leading global technology solutions-provider, renown for its innovative approach.
                    </h5>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="separator separator-primary"></div>
            <div className="section-about-solutions">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <img alt="Cybersecurity Experts, NexOne Technologies" className="about-left" src={require("assets/img/about-solutions.svg")}></img>  
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">Our Solutions</h2>
                    <h5 className="description"> 
                    We provide our clients with:<br /><br />
                    <ul>
                      <li><strong><a href="/solutions#msp">Managed IT Solutions</a></strong></li>
                      <li><strong><a href="/cybersecurity">Cybersecurity</a></strong></li>
                      <li><strong><a href="/solutions#cloud">Cloud Solutions</a></strong></li>
                      <li><strong><a href="/solutions#voip">Cloud VOIP</a></strong></li>
                      <li><strong><a href="/solutions">Office 365</a></strong></li>
                    </ul>

                    So, you can focus on what you do best, running your business.
                    </h5>

                  </Col>
                </Row>
              </Container>
            </div>
        </div>
          <div className="above-footer-consult">
            <Container>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <span>Get a Free Consultation</span>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="6">
                      <a href="/consultation" target="_blank" className="consult-button">Get Started</a>
                      </Col>
                    </Row>
            </Container>
          </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
