import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

// core components
import PagesNavbar from "components/Navbars/PagesNavbar.js";
import ConsultationHeader from "components/Headers/ConsultationHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [secondFocus, setSecondFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [bestTimeFocus, setBestTimeFocus] = React.useState(false);
  const [messageFocus, setMessageFocus] = React.useState(false);

  const [sendingMail, setSendingMail] = React.useState(false);
  const [grecaptchaError, setGrecaptchaError] = React.useState(-1);

  const [successAlert, setSuccessAlert] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    initiateGrecaptcha();
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const initiateGrecaptcha = () => {
    /*global grecaptcha*/
    const grecaptchaIntervalRef = setInterval(() => {
      if (grecaptcha.render) {
        clearInterval(grecaptchaIntervalRef);
        grecaptcha.render("grecaptcha", {
          sitekey: "6LfLJKUUAAAAABd4wa4OVcEz3vDYujGeFlX0NtQu",
          callback: () => {
            if (grecaptcha.getResponse().length === 0) {
              setGrecaptchaError(1);
            } else {
              setGrecaptchaError(0);
            }
          },
        });
      }
    }, 100);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (grecaptchaError === -1) {
      setGrecaptchaError(1);
      return;
    } else if (grecaptchaError === 0) {
      setSendingMail(true);
      const data = {
        fullName: document.getElementById("nx-cf-fullName").value,
        email: document.getElementById("nx-cf-email").value,
        phoneNumber: document.getElementById("nx-cf-phoneNumber").value,
        bestTime: document.getElementById("nx-cf-bestTime").value,
        message: document.getElementById("nx-cf-message").value,
      };
      let body = `
      Hi, You have received a new request for NexOne Technologies. Please see below for contact information:
      <br />
      <br />
      <b>Full Name</b>: ${data.fullName}<br />
      <b>Email</b>: ${data.email}<br />
      <b>Phone Number</b>: ${data.phoneNumber}<br />
      <b>Best Time</b>: ${data.bestTime}<br />
      <b>message</b>: ${data.message}<br />`;

      /*global Email*/
      Email.send({
        Host: "smtp.office365.com",
        Username: "sales@nexonetechnologies.com",
        Password: "JayliJayki11!",
        To: "sales@nexonetechnologies.com",
        From: "sales@nexonetechnologies.com",
        Subject: "NexOne Technologies - Consultation Contact Request",
        Body: body,
      }).then((message) => {
        setSendingMail(false);
        if (message === "OK") {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
          }, 4000);
          document.getElementById("contactForm").reset();
          grecaptcha.reset();
        } else {
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 4000);
        }
      });
    }
  };

  return (
    <>
      <PagesNavbar />
      <div className="wrapper support-page">
        <ConsultationHeader />
        <div className="section section-support">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Reach out to us!</h2>
                <div className="has-decoration"></div>
                <h5 className="description">
                  Request a consultation or simply get in touch with us.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="support-options">
              <form id="contactForm" onSubmit={handleSubmit}>
                <Row>
                  <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                    <InputGroup
                      className={
                        "input-lg" + (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Full Name..."
                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        id="nx-cf-fullName"
                        required
                      ></Input>
                    </InputGroup>

                    <InputGroup
                      className={
                        "input-lg" + (secondFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email..."
                        type="email"
                        onFocus={() => setSecondFocus(true)}
                        onBlur={() => setSecondFocus(false)}
                        id="nx-cf-email"
                        required
                      ></Input>
                    </InputGroup>

                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                        <InputGroup
                          className={
                            "input-lg" + (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons tech_mobile"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Phone Number..."
                            type="number"
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            id="nx-cf-phoneNumber"
                            required
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="4">
                        <InputGroup
                          className={
                            "input-lg" +
                            (bestTimeFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-2_time-alarm"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="select"
                            onFocus={() => setBestTimeFocus(true)}
                            onBlur={() => setBestTimeFocus(false)}
                            id="nx-cf-bestTime"
                            className="p-0"
                          >
                            <option>Select Best Time</option>
                            <option value="9am-10am">9:00am-10:00am</option>
                            <option value="10am-11am">10:00am-11:00am</option>
                            <option value="11am-12pm">11:00am-12:00pm</option>
                            <option value="12pm-1pm">12:00pm-1:00pm</option>
                            <option value="1pm-2pm">1:00pm-2:00pm</option>
                            <option value="2pm-3pm">2:00pm-3:00pm</option>
                            <option value="3pm-4pm">3:00pm-4:00pm</option>
                            <option value="4pm-5pm">4:00pm-5:00pm</option>
                          </Input>
                        </InputGroup>
                      </Col>
                    </Row>

                    <InputGroup
                      className={
                        "input-lg" + (messageFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-2_chat-round"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Type a message..."
                        type="textarea"
                        onFocus={() => setMessageFocus(true)}
                        onBlur={() => setMessageFocus(false)}
                        id="nx-cf-message"
                        style={{
                          border: "1px solid",
                          borderLeft: 0,
                          borderRadius: "0px 30px 30px 0px",
                          borderColor: messageFocus ? "#f96332" : "#E3E3E3",
                        }}
                      ></Input>
                    </InputGroup>

                    <div className="form-group col-12">
                      <div id="grecaptcha"></div>
                      {grecaptchaError === 1 && (
                        <div className="invalid-feedback d-block">
                          Please Verify Captcha.
                        </div>
                      )}
                    </div>

                    <Alert color="success" isOpen={successAlert}>
                      <Container>
                        <div className="alert-icon">
                          <i className="now-ui-icons ui-2_like"></i>
                        </div>
                        <strong>Thank you!</strong> We will get back to you
                        shortly.
                        <button
                          type="button"
                          className="close"
                          onClick={() => setSuccessAlert(false)}
                        >
                          <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                          </span>
                        </button>
                      </Container>
                    </Alert>

                    <Alert color="danger" isOpen={errorAlert}>
                      <Container>
                        <div className="alert-icon">
                          <i className="now-ui-icons objects_support-17"></i>
                        </div>
                        <strong>Hmm!</strong> Something went wrong.
                        <a
                          href="https://app.purechat.com/w/nexone"
                          style={{
                            marginLeft: 10,
                            color: "white",
                            textDecoration: "underline",
                          }}
                        >
                          Contact Support
                        </a>
                        <button
                          type="button"
                          className="close"
                          onClick={() => setErrorAlert(false)}
                        >
                          <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                          </span>
                        </button>
                      </Container>
                    </Alert>

                    <div className="send-button">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        size="lg"
                        disabled={sendingMail}
                        style={{
                          opacity: sendingMail ? 0.5 : 1,
                          pointerEvents: sendingMail ? "none" : "auto",
                        }}
                      >
                        Send Message
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
              <div className="support-link">
                <a href="https://app.purechat.com/w/nexone" target="_blank">
                  CHAT NOW
                </a>
                <div className="has-decoration"></div>
                <span>Click to open live chat with a support specialist</span>
              </div>
            </div>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
