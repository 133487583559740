/*

=========================================================
* NexOne Technologies
=========================================================

* Designed by www.creativeavenuegroup.com

=========================================================

* The above copyright notice. This website was developed for NexOne Technologies by Creative Avenue.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/css/nexone.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
//import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
//import LoginPage from "views/pages/LoginPage.js";
import Solutions from "views/pages/Solutions.js";
import Nexone from "views/pages/Nexone.js";
import Support from "views/pages/Support.js";
import Consultation from "views/pages/Consultation.js";
//import Contact from "views/pages/contact.js";
import Cybersecurity from "views/pages/Cybersecurity.js";
//import ProfilePage from "views/pages/ProfilePage.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/solutions"
          render={(props) => <Solutions {...props} />}
        />
        <Route
          path="/cybersecurity"
          render={(props) => <Cybersecurity {...props} />}
        />
        <Route
          path="/consultation"
          render={(props) => <Consultation {...props} />}
        />
        <Route
          path="/about"
          render={(props) => <Nexone {...props} />}
        />
        <Route
          path="/support"
          render={(props) => <Support {...props} />}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
