import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <div className={"fixed-top " + "navbar-top"}>
        <Row>
          <Col md="9">
          <a href="tel://+18558656351">(855) 865-6351</a>
          </Col>
          <Col md="3">
          <a href="mailto:support@nexoneit.com" target="_blank">support@nexoneit.com</a>
          </Col>
        </Row>
      </div>
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>

          <div className="navbar-translate">
            <NavbarBrand
              href="https://nexoneit.com/"
              target="_blank"
              id="navbar-brand"
            >
             <div className="nexone-logo" alt="NexOne Technologies, a cybersecurity and managed IT services provider."
            ></div>
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              NexOne Technologies
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink to="/index" tag={Link}>
                  Home
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  
                  <p>Solutions</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/solutions#msp" tag={Link}>
                    <i className="now-ui-icons business_chart-pie-36 mr-1"></i>
                    Managed IT Services
                  </DropdownItem>
                  <DropdownItem
                    href="/cybersecurity"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Cybersecurity
                  </DropdownItem>
                  <DropdownItem
                    href="/solutions#backups"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Backups & Disaster Recovery Planning
                  </DropdownItem>

                  <DropdownItem
                    href="/solutions#voip"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Cloud VOIP
                  </DropdownItem>
                  <DropdownItem
                    href="/solutions"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Office 365
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href="/support"
                >
                  
                  <p>Support</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/about"
                >
                  
                  <p>NexOne</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="info"
                  href="consultation"
                  id="upgrade-to-pro"
                >
                  
                  <p> Free Consultation </p> <i class="now-ui-icons ui-1_send"></i> 
                </Button>
                <UncontrolledTooltip target="#upgrade-to-pro">
                  Get a Free Phone Consultation
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/nexoneit"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/nexoneit"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/nexoneit"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
