/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <span>Services</span>
              <br />
              <a
                href="/solutions#msp"
              >Managed IT Services
              </a>
              <br />
              <a
                href="cybersecurity"
              >Cybersecurity
              </a>
              <br />
              <a
                href="/solutions#backups"
              >Backup & Disaster Recovery Planning
              </a>
              <br />
              <a
                href="/solutions#cloud"
              >Cloud Services
              </a>
              <br />
              <a
                href="/solutions#voip"
              >Cloud VOIP
              </a>
              <br />
              <a
                href="/solutions"
              >Office 365
              </a>
            </li>

            <li>
            <span>Company</span>
            <br />
              <a
                href="/about"
              >
                Industries
              </a>
              <br />
              <a
                href="/support"
              >
                Support
              </a><br />
              <a
                href="#"
              >
                Referral Program
              </a>
              <br />
              <a
                href="/consultation"
              >
                Contact Us
              </a>
              <br />
              <a
                href="#"
              >
                Our Blog
              </a>
              <br />
              <a
                href="/about"
              >
                About Us
              </a>
            </li>
            
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, NexOne Technologies LLC. 
          
           Designed by{" "}
          <a
            href="https://www.creativeavenuegroup.com"
            target="_blank"
          >
            Creative Avenue
          </a>
          .
        </div>
      </Container>

    <div className="footer-bottom">
        <div className="footer-logo">
          <img alt="Cybersecurity Experts, NexOne Technologies" src={require("assets/img/footer-logo.png")}></img>  
        </div>
    </div>
      
    </footer>

  );
}

export default DarkFooter;
